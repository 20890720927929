<template>
  <ContentWrapper :title="labels.MENU_NEWS" back>
    <template slot="actions">
      <el-button size="small" type="success" @click="submitForm('newsForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_UPDATE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      ref="newsForm"
      :model="news"
      :rules="rules"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item prop="title" :label="labels.LBL_TITLE">
            <el-input v-model="news.title"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_SUBTITLE">
            <el-input v-model="news.subtitle"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="editor" :label="labels.LBL_EDITOR">
            <el-input v-model="news.editor"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_PUBLISH_NEWS">
            <el-switch v-model="news.is_published"></el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item>
            <el-checkbox v-model="news.send_mail">
              {{ labels.LBL_SEND_MAIL }}
            </el-checkbox>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="content" :label="labels.LBL_CONTENT">
            <RichEditor v-model="news.content" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-form-item :label="labels.LBL_IMAGES">
          <div class="upload-images">
            <div class="uploader">
              <label style="display: block">
                <i class="el-icon-plus uploader-icon"></i>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  style="display: none"
                  @change="handleChange"
                />
              </label>
            </div>

            <div
              v-for="(img, index) in imageList"
              :key="`img-${index}`"
              class="image"
            >
              <img
                :src="createPosterUrl(img.image_url ? img.image_url : img)"
                alt="Image Thumb"
              />
              <i
                class="el-icon-close remove-icon"
                @click="removeImage(index, img)"
              ></i>
            </div>
          </div>
        </el-form-item>
      </el-row>
    </el-form>
  </ContentWrapper>
</template>

<script>
  import { mapState } from "vuex";
  import { labels, rules } from "@/common";
  import News from "@/models/news";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";
  import RichEditor from "@/components/RichEditor";

  export default {
    name: "EditNews",

    components: {
      ContentWrapper,
      RichEditor,
    },

    props: {
      newsId: {
        type: [String, Number],
      },
    },

    data() {
      return {
        labels,

        news: new News(),
        imageList: [],

        rules,
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        newsDetail: (state) => state.news.newsDetail,
      }),
    },

    watch: {
      newsId: {
        immediate: true,
        handler(nv) {
          this.loadNewsDetail(nv);
        },
      },

      newsDetail: {
        handler(nv) {
          this.setNewsDetail(nv);
        },
      },
    },

    methods: {
      loadNewsDetail(id) {
        this.$store.dispatch("news/getNewsDetail", id);
      },

      setNewsDetail(detail) {
        this.news = { ...this.news, ...detail };
        this.news.images = [];
        this.news.is_published = !!detail.published_at;
        this.imageList = detail.news_images;
      },

      createPosterUrl(image) {
        if (typeof image.name === "string") {
          const url = URL.createObjectURL(image);
          return url;
        }
        return image;
      },

      handleChange(e) {
        const fileList = [...e.target.files];
        this.imageList = [...fileList, ...this.imageList];
        this.news.images = [...this.news.images, ...fileList];
      },

      removeImage(index, image) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            if (image.created_at) {
              this.$store
                .dispatch("news/deleteImages", {
                  ids: [image.id],
                })
                .then(() => {
                  this.imageList.splice(index, 1);
                });
            } else {
              this.imageList.splice(index, 1);
            }
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("news/updateNews", this.news);
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  .upload-images {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
  }

  .image {
    position: relative;
    margin: 0.5rem;
    height: 178px;
    width: 178px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }

    .remove-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 0.25rem;
      border-radius: 1rem;
      background: white;
      border: 1px solid lightgray;
      display: none;
      cursor: pointer;
    }

    &:hover .remove-icon {
      display: block;
    }
  }

  .uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin: 0.5rem;

    .uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    &:hover {
      border-color: #409eff;
    }
  }

  .add-image {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;
  }
</style>
